import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import classnames from 'classnames';
import {
  StatusInput,
  PhoneInput,
  ContactTypeSelector,
  DatePicker,
  Input,
  fromStringToE164,
} from '@united-talent-agency/components';
import * as colors from '../../../styles/colors';
import * as elements from '../../../styles/elements';
import * as sizes from '../../../styles/sizes';
import { CALL_FORM, CONTACT_INPUT } from '../../../support/cypressTags';

import {
  TextArea,
  PersonInput,
  DoneButtons,
} from '@united-talent-agency/julius-frontend-components';

import {
  searchDeskContacts,
  getPersonPrivateContacts,
} from '@united-talent-agency/julius-frontend-store';
import { getPeopleByTypeBuckets } from '../../../api/people';
import { getOutlookContacts } from '../../../api/outlook';

import {
  existingContactPhone,
  existingContactNotPhone,
  updateDeskAddress,
} from '../../../support/contact';
import { useDebouncedCallback } from 'use-debounce';
import withHooksHOC from '../../../hocs/withHooksHOC';
import FormMessage from '../../../components/FormMessage';
import dateToDateString from '../../../utils/dateToDateString';
import dateToTime from '../../../utils/dateToTime';
import dateToAmPm from '../../../utils/dateToAmPm';

const CONTACT_PRIORITIES = [
  'Office Phone',
  'Assistant Phone',
  'Mobile Phone',
  'Home Phone',
  'Email',
  'Assistant Email',
  'Personal Email',
  'Message',
  'Fax Number',
  'Unknown',
];

/************************************************************
 *         Get Primary Contact from contacts array          *
 * -------------------------------------------------------- *
 *  1. If there are multiple Primary set, the order is:     *
 *     Phone > Email > Other                                *
 *  2. If no priority contact exists, then order will be:   *
 *     Work > Assistant Phone > Mobile > Home > Email >     *
 *     Assistant email > Personal Email > Message > Fax     *
 ************************************************************/
export const getPrimaryContact = (contacts) => {
  let primaryContact = null;
  if (contacts) {
    const primaryContacts = contacts.filter((c) => c.primary);

    if (primaryContacts.length > 0) {
      // has primary contacts
      CONTACT_PRIORITIES.forEach((contactType) => {
        const contact = primaryContacts.find((c) => c.contactType === contactType);
        if (contact && !primaryContact) {
          primaryContact = contact;
          return;
        }
      });
    } else {
      // no primary contacts
      CONTACT_PRIORITIES.forEach((contactType) => {
        const contact = contacts.find((c) => c.contactType === contactType);
        if (contact && !primaryContact) {
          primaryContact = contact;
          return;
        }
      });
    }
  }

  return primaryContact || {};
};

const checkPhoneNumberValidity = (callTodo) => {
  const hasContactInfo = ![null, undefined].includes(callTodo?.contactInfo);

  if (!hasContactInfo) {
    return false;
  }

  const isPhoneType = /Phone|Fax Number/.test(callTodo?.contact?.contactType);

  if (!isPhoneType) {
    return false;
  }

  const e164ContactInfo = fromStringToE164(callTodo.contactInfo);
  const hasValidExtension = ![null, undefined].includes(e164ContactInfo?.extension);
  const hasValidCountryCode = ![null, undefined].includes(e164ContactInfo?.countryCode);
  const hasValidNumber = ![null, undefined].includes(e164ContactInfo?.number);

  const validations = [];
  const [number, extension] = callTodo.contactInfo.split(';');

  // If the phone number is being used, its validation will be added.
  if (number) {
    const isAValidNumber = hasValidCountryCode && hasValidNumber;
    validations.push(isAValidNumber);
  }

  // If the extension is being used, its validation will be added.
  if (extension) {
    validations.push(hasValidExtension);
  }

  return validations.every((value) => !!value);
};

export const validateCallFormRequiredFields = (callTodo, callbackMissingFields) => {
  const missingFields = [];

  if (
    !(
      callTodo?.recipientId ||
      (callTodo?.recipientName && callTodo?.recipientName.trim().length > 0)
    )
  ) {
    missingFields.push('Name');
  }
  if (!callTodo?.status) {
    missingFields.push('Status');
  }
  if (!callTodo?.occurrence_date) {
    missingFields.push('Date');
  }
  if (!callTodo?.contact?.contact) {
    missingFields.push('Contact Info');
  }
  if (!callTodo?.contact?.contactType) {
    missingFields.push('Type');
  }

  if (missingFields.length) {
    callbackMissingFields && callbackMissingFields(missingFields);
    return false;
  }

  return true;
};

const Component = ({
  onChange,
  callForm = {},
  callTodo = {},
  deskId,
  dispatch,
  onSave,
  addToast,
  styles = {},
  statuses,
  onClose,
}) => {
  const userRegion = useMemo(() => (navigator?.language ?? 'en-US')?.split('-')?.[1], []);
  const initialDate = useMemo(() => new Date(callTodo?.occurrenceDate), [callTodo?.occurrenceDate]);

  // Refs
  const phoneInputRef = useRef(null);
  const dateStringRef = useRef(dateToDateString(initialDate));
  const timeStringRef = useRef(dateToTime(initialDate));
  const twelveHourPeriodStringRef = useRef(dateToAmPm(initialDate));

  // States
  const [isSaving, setIsSaving] = useState(false);
  const [dateString, setDateString] = useState('');
  const [timeString, setTimeString] = useState('');
  const [twelveHourPeriodString, setTwelveHourPeriodString] = useState('');
  const [personSearchResults, setPersonSearchResults] = useState([]);
  const [outlookSearchResults, setOutlookSearchResults] = useState([]);
  const [deskContactResults, setDeskContactResults] = useState([]);
  const [recipientIdPrivateContacts, setRecipientIdPrivateContacts] = useState([]);
  const [deskAddressBookEntryContacts, setDeskAddressBookEntryContacts] = useState([]);

  const dateToInputStates = (date) => {
    const newTimeDisplay = date.toLocaleString(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    const newDateString = newTimeDisplay.split(' ')[0].slice(0, -1);
    const newTimeString = newTimeDisplay.split(' ')[1];
    const newTwelveHourPeriodString = newTimeDisplay.split(' ')[2];

    dateStringRef.current = newDateString;
    timeStringRef.current = newTimeString;
    twelveHourPeriodStringRef.current = newTwelveHourPeriodString;
    setDateString(newDateString);
    setTimeString(newTimeString);
    setTwelveHourPeriodString(newTwelveHourPeriodString);
  };

  const dateInputStatesClear = () => {
    const newDateString = '';
    const newTimeString = '';
    const newTwelveHourPeriodString = '';

    dateStringRef.current = newDateString;
    timeStringRef.current = newTimeString;
    twelveHourPeriodStringRef.current = newTwelveHourPeriodString;
    setDateString(newDateString);
    setTimeString(newTimeString);
    setTwelveHourPeriodString(newTwelveHourPeriodString);
  };

  useEffect(() => {
    const { _id, recipientId, recipientName } = callTodo;
    const recipient = { name: recipientName, id: recipientId };

    const recipientContacts = updateDeskAddress(_id, deskId, recipient, dispatch);
    if (recipientContacts) {
      setDeskAddressBookEntryContacts(recipientContacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callTodo, deskId]);

  /**
   * Manages the date component's states.
   */
  useEffect(() => {
    if (callTodo?.occurrence_date) {
      const date = new Date(callTodo?.occurrence_date);
      dateToInputStates(date);
      // onChange cannot be called here to avoid loops
      callTodo.occurrence_date = date.getTime();
    } else {
      dateInputStatesClear();
    }
  }, [callTodo]);

  const onSelectPerson = useCallback(
    (person) => {
      //only "master-data" people have private contacts
      if (person && person.type && person.type !== 'Outlook' && person._id) {
        dispatch(getPersonPrivateContacts(person._id))
          .then((response) => {
            const privateContacts = response?.body?.privateContacts || [];
            setRecipientIdPrivateContacts(privateContacts);
          })
          .catch((error) => {
            console.error(error);
          });
      }

      const updatedCallTodo = {
        ...callTodo,
        recipientId: person,
        recipientName: person.name,
        contact: getPrimaryContact(person.contacts),
        companyName: person.companyId && person.companyId.name,
      };

      updatedCallTodo.contactInfo = updatedCallTodo.contact && updatedCallTodo.contact.contact;

      //reset desk address book search results after person select

      setDeskContactResults([]);
      setPersonSearchResults([]);
      setOutlookSearchResults([]);

      callForm.outlook && (callForm.outlook.results = []);

      onChange && onChange(updatedCallTodo);
    },
    [callForm.outlook, callTodo, dispatch, onChange]
  );

  const searchPerson = useDebouncedCallback((name, desk) => {
    name = name?.trim();

    getPeopleByTypeBuckets(name)
      .then((result) => {
        setPersonSearchResults(result.data);
      })
      .catch((error) => {
        console.error(error);
      });

    dispatch(searchDeskContacts(desk, name))
      .then((result) => {
        const deskPeople = result.body || [];
        setDeskContactResults(deskPeople);
      })
      .catch((error) => {
        console.error(error);
      });

    getOutlookContacts(name, desk, dispatch)
      .then(setOutlookSearchResults)
      .catch((error) => {
        console.error(error);
      });
  }, 500);

  const isPhoneType = useMemo(
    () => callTodo?.contact && /Phone|Fax Number/.test(callTodo?.contact?.contactType),
    [callTodo?.contact]
  );

  const isValidRequiredFields = useMemo(() => validateCallFormRequiredFields(callTodo), [callTodo]);

  const isValidPhoneNumber = useMemo(
    () => !isPhoneType || checkPhoneNumberValidity(callTodo),
    [callTodo, isPhoneType]
  );

  const submit = useCallback(
    async (e) => {
      e.preventDefault();

      const isValid = validateCallFormRequiredFields(callTodo, (_missingFields) => {
        const lf = new Intl.ListFormat('en');
        const formatted = lf.format(_missingFields);
        const areIs = _missingFields.length > 1 ? 'are' : 'is';
        const errorMessage = `${formatted} ${areIs} required to create a Call Record.`;

        addToast && addToast(errorMessage, { appearance: 'error', autoDismiss: true });
      });

      if (!isValid) {
        return;
      }

      if (isPhoneType && callTodo?.contactInfo?.length && !checkPhoneNumberValidity(callTodo)) {
        addToast &&
          addToast('Phone number must be a valid number to create a Call Record', {
            appearance: 'error',
            autoDismiss: true,
          });
        return;
      }

      // Add description as first element of notes
      callTodo.notes = [{ note: callTodo.description }];

      setIsSaving(true);
      // onSave creates endpoint call Promise via call-todo.saveCall
      if (onSave) {
        onSave(callTodo)
          .then(() => {
            phoneInputRef.current?.setCountry(userRegion);
          })
          .finally(() => {
            setIsSaving(false);
          });
      }
    },
    [addToast, callTodo, isPhoneType, onSave, userRegion]
  );

  const isExistingOutlookContact = useCallback(
    () =>
      callTodo.recipientId?.outlook &&
      callTodo.contact?.contact &&
      (existingContactPhone(callTodo.recipientId, callTodo.contact) ||
        existingContactNotPhone(callTodo.recipientId, callTodo.contact)),
    [callTodo.contact, callTodo.recipientId]
  );

  const outlook = { results: outlookSearchResults };

  const currentStatus = useMemo(() => {
    const foundStatus = statuses?.find(({ status }) => status === callTodo.status);

    return foundStatus || statuses?.[0];
  }, [callTodo.status, statuses]);

  callTodo.status = currentStatus && currentStatus.status;

  //combine "master data" public contacts with "master data" private contacts (that their desk has access to)
  const masterDataContacts = callTodo.recipientId
    ? (callTodo.recipientId.contacts || []).concat(recipientIdPrivateContacts || [])
    : [];

  //combine masterDataContacts with desk address book entries (just fall-through logic, should never be both?)
  const existingContacts = masterDataContacts
    .concat(deskAddressBookEntryContacts)
    .map(({ _id, contactType = 'Unknown', contact = '', primary }) => ({
      _id,
      contactType,
      value: contact,
      primary,
      private: contactType && !contact,
    }));

  const contactAddable =
    !isExistingOutlookContact() &&
    callTodo.recipientId?.type !== 'Employee' &&
    (!callTodo.recipientId ||
      (callTodo.recipientId?._id?.length > 0 && callTodo.recipientId?.canEdit) ||
      callTodo.recipientId?.type === 'Desk Contact');

  let tooltipMessage = undefined;

  if (callTodo.recipientId?.type === 'Employee') {
    tooltipMessage = 'Contact information for an employee can only be edited (by them) in Workday.';
  } else if (callTodo.recipientId?.type === 'Client') {
    tooltipMessage = 'Please contact a client team member to make changes to this client profile.';
  } else {
    tooltipMessage = 'Phonesheet cannot edit Outlook (O) or Personal (P) contacts.';
  }

  const handleDatetimeChange = useCallback(() => {
    // use ref to get latest values
    const dateStr = `${dateStringRef.current}, ${timeStringRef.current} ${twelveHourPeriodStringRef.current}`;
    const newDate = new Date(dateStr);
    onChange && onChange({ ...callTodo, occurrence_date: newDate.getTime() });
  }, [callTodo, onChange]);

  const handleOnChangePersonInput = useCallback(
    (value) => {
      callTodo.recipientId = null;
      callTodo.recipientName = value;

      value && searchPerson(value, deskId);
      onChange && onChange(callTodo);
    },
    [callTodo, deskId, onChange, searchPerson]
  );

  const handleOnChangePhoneInput = (value) => {
    delete callTodo.contact._id;
    const existing = existingContactPhone(callTodo.recipientId, callTodo.contact);
    if (existing) {
      callTodo.contact._id = existing._id;
    }

    onChange &&
      onChange({
        ...callTodo,
        contactInfo: value,
        contact: {
          ...callTodo?.contact,
          contact: value,
        },
      });
  };

  const phoneNumberIsValid = useMemo(() => checkPhoneNumberValidity(callTodo), [callTodo]);

  return (
    <form
      data-valid={isValidRequiredFields && isValidPhoneNumber}
      className={styles.container}
      onSubmit={submit}
      data-cy={CALL_FORM.CALL_FORM}
    >
      <div className={styles.mainContainer}>
        <div className={styles.subContainer}>
          <div className={styles.statusAndNameWrapper}>
            <div
              data-cy={CALL_FORM.STATUS_TEXT_BOX}
              className={classnames(styles.section, styles.statusInput)}
            >
              {!!statuses && (
                <StatusInput
                  options={statuses.filter((code) => code.name && code.status)}
                  value={currentStatus}
                  onChange={(value) => {
                    onChange &&
                      onChange({
                        ...callTodo,
                        status: value.status,
                      });
                  }}
                />
              )}
            </div>

            <div
              data-cy={CALL_FORM.NAME_TEXT_BOX}
              className={classnames(styles.section, styles.nameInput)}
            >
              <PersonInput
                focus
                results={[...personSearchResults, ...deskContactResults]}
                outlook={outlook}
                showOutlook={true}
                value={callTodo.recipientName ?? ''}
                onChange={handleOnChangePersonInput}
                onSelect={onSelectPerson}
              />
            </div>
          </div>

          <div className={classnames(styles.section, styles.contactInput)}>
            <div
              id="contact-type-selector"
              data-cy={CALL_FORM.TYPE_SELECT_BOX}
              className={classnames({
                [classnames('invalid', styles.invalidFieldContainer)]:
                  !callTodo?.contact?.contactType || callTodo?.contact?.contactType === 'Unknown',
              })}
            >
              <ContactTypeSelector
                value={{
                  contactType: callTodo?.contact?.contactType || 'Unknown',
                  value: callTodo?.contact?.contact,
                }}
                existing={existingContacts}
                onChange={({ contactType = 'Unknown', value }) => {
                  if (onChange) {
                    onChange({
                      ...callTodo,
                      contact: { contactType, contact: value },
                      contactInfo: value,
                    });
                  }
                }}
                extSeparator=" x"
                addable={contactAddable}
                tooltipMessage={tooltipMessage}
                cypressTags={{
                  existingContact: CONTACT_INPUT.EXISTING_CONTACT,
                  addContact: CONTACT_INPUT.ADD_CONTACT,
                  contactType: CONTACT_INPUT.CONTACT_TYPE,
                }}
              />
            </div>
            {callTodo?.contact ? (
              isPhoneType ? (
                <div
                  id="phone-or-fax-input"
                  data-cy={CALL_FORM.PHONE_TEXT_BOX}
                  className={classnames(styles.phoneOrFaxInputContainer, {
                    [classnames('invalid', styles.invalidFieldContainer)]:
                      !callTodo?.contactInfo || !phoneNumberIsValid,
                  })}
                >
                  <PhoneInput
                    ref={(ref) => (phoneInputRef.current = ref)}
                    userRegion={userRegion}
                    value={callTodo?.contactInfo}
                    title={callTodo.contact.contactType}
                    onChange={handleOnChangePhoneInput}
                    disabled={!contactAddable}
                    cypressTags={{
                      phoneNumberInput: CONTACT_INPUT.PHONE_NUMBER_INPUT,
                    }}
                  />

                  {!phoneNumberIsValid && !!callTodo?.contactInfo && (
                    <FormMessage>Invalid Number</FormMessage>
                  )}
                </div>
              ) : (
                <div
                  id="non-phone-or-fax-input"
                  data-cy={CALL_FORM.NORMAL_TEXT_BOX}
                  style={{ minWidth: 250, flex: 1 }}
                  className={classnames({
                    [classnames('invalid', styles.invalidFieldContainer)]: !callTodo?.contactInfo,
                  })}
                >
                  <Input
                    /**
                     * The value should always be a string, because if it comes as undefined,
                     * it will prevent the primitive input value from changing and the label will remain over the value.
                     */
                    value={callTodo?.contactInfo ?? ''}
                    title={callTodo.contact.contactType}
                    onChange={(value) => {
                      const existing = existingContactNotPhone(
                        callTodo.recipientId,
                        callTodo.contact
                      );

                      const updatedCallTodo = {
                        ...callTodo,
                        contactInfo: value,
                        contact: {
                          ...callTodo.contact,
                          contact: value,
                          _id: existing && existing._id ? existing._id : undefined,
                        },
                      };

                      onChange && onChange(updatedCallTodo);
                    }}
                    disabled={!contactAddable}
                  />
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={styles.subContainer}>
          <div
            data-cy={CALL_FORM.DATE_TEXT_BOX}
            className={classnames(styles.section, styles.datetimePicker, {
              [styles.invalidDatetimePicker]: !callTodo?.occurrence_date,
            })}
          >
            <DatePicker
              timeIncluded
              inputFieldReadOnly
              timeString={timeString}
              dateString={dateString}
              twelveHourPeriod={twelveHourPeriodString}
              onFocus={() => {
                if (!callTodo?.occurrence_date) {
                  const now = new Date();
                  dateToInputStates(now);
                }
              }}
              onChangeTime={(time) => {
                setTimeString(time);
                if (!callTodo?.occurrence_date) {
                  const now = new Date();
                  dateToInputStates(now);
                }

                if (!time || time.length < 4) {
                  return;
                }

                timeStringRef.current = time;
                handleDatetimeChange();
              }}
              onChangeTwelveHourPeriod={(period) => {
                setTwelveHourPeriodString(period);

                if (!period) {
                  return;
                }

                twelveHourPeriodStringRef.current = period;
                handleDatetimeChange();
              }}
              onChange={(date) => {
                if (!callTodo?.occurrence_date) {
                  const now = new Date();
                  dateToInputStates(now);
                }

                setDateString(date);

                if (!date) {
                  return;
                }

                dateStringRef.current = date;
                handleDatetimeChange();
              }}
            />
          </div>

          <div
            data-cy={CALL_FORM.NOTES_TEXT_BOX}
            className={classnames(styles.section, styles.notesInput)}
          >
            <TextArea
              key="notes"
              id="notes-input"
              title="Notes"
              onChange={(notes) => {
                onChange && onChange({ ...callTodo, description: notes });
              }}
              value={callTodo.description}
            />
          </div>

          <div className={styles.doneButtons}>
            <DoneButtons
              isActive
              isSaving={isSaving}
              onDone={() => {
                onClose && onClose();
                if (phoneInputRef.current) {
                  phoneInputRef.current.setCountry(userRegion);
                }
              }}
              cypressSaveTag={CALL_FORM.CHECKMARK_BUTTON}
              cypressCloseTag={CALL_FORM.CANCEL_BUTTON}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    containerName: 'call-form-container',
    containerType: 'inline-size',

    ['&[data-valid="false"] #done-button']: {
      cursor: 'not-allowed',
    },
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'start',
    background: colors.contentBackground,
    border: `1px solid ${colors.darkBorder}`,
    padding: 6,
    gap: 6,
    [`@container call-form-container (max-width: 1025px)`]: {
      flexWrap: 'wrap',
    },
    [`@container call-form-container (max-width: 768px)`]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  subContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    [`@container call-form-container ${sizes.mobileBreakpoint}`]: {
      flexWrap: 'wrap',
    },
    [`@container call-form-container (max-width: 425px)`]: {
      flexDirection: 'column',
      alignItems: 'normal',
    },
  },
  section: {},
  button: helpers.merge(elements.reset, elements.actionable, {
    padding: 10,
    lineHeight: 0,
  }),
  statusAndNameWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'start',
    [`@container call-form-container (max-width: 425px)`]: {
      alignSelf: 'stretch',
    },
  },
  statusInput: {
    width: 70,
  },
  nameInput: {
    flex: 1,
    minWidth: 150,
    width: '100%',
  },
  contactInput: {
    display: 'flex',
    flex: 1,
    marginTop: '0px!important',

    ['& .invalid ~ .invalid input']: {
      borderLeftColor: 'transparent',
    },
    ['& .invalid ~ .invalid button']: {
      borderLeftColor: 'transparent',
    },
    ['& #phone-or-fax-input.invalid input']: {
      borderLeftColor: 'transparent',
    },
  },
  phoneOrFaxInputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 2,
    width: '100%',
  },
  invalidFieldContainer: {
    ['& button']: {
      borderColor: '#CB0B2F',
    },
    ['& input']: {
      borderColor: '#CB0B2F',
    },
  },
  datetimePicker: {
    position: 'relative',
    display: 'flex',
    minWidth: 170,

    '&>button': {
      display: 'flex',
      flex: 1,
    },

    '&>div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      flex: 1,
      top: '100%',
    },
  },
  invalidDatetimePicker: {
    ['& button']: {
      borderColor: '#CB0B2F',
    },
  },
  notesInput: {
    flex: 1,
    minWidth: 200,
    width: '100%',
    '&&>*': {
      display: 'flex',
    },
  },
  doneButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0!important',
    marginBottom: '0!important',
    marginLeft: 'auto',
    '&&>*': {
      justifyContent: 'center',
      margin: 0,
    },
  },
  alertContainer: {
    textAlign: 'center',
    width: '450px',
    fontSize: '12px',
    background: 'white',
    opacity: 1,
    padding: '40px',
    boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
    color: '#4a4a4a',
    border: '1px solid #4a4a4a',
  },
  alertButton: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderColor: '#000',
    margin: '5px',
  }),
  private: {
    position: 'absolute',
  },
  privateLabel: {
    fontSize: 12,
    fontWeight: '100',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    ' input': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },
    ' input:checked': {
      display: 'block',
    },
    ' .privateCheckbox:after': {
      left: '9px',
      top: '5px',
      width: '5px',
      height: '10px',
      border: 'solid white',
      borderWidth: '0 3px 3px 0',
      transform: 'rotate(45deg)',
    },
  },
  privateCheckbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 15,
    width: 15,
    backgroundColor: '#eee',
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  },
});

const withState = connect((state) => {
  const callForm = state?.callForm;
  const deskId = state?.desk?.current?._id;
  const desk = state?.desk?.current;
  const user = state?.user;

  return { callForm, deskId, user, desk };
});

const CallForm = withState(withStyles(withHooksHOC(Component)));

export default CallForm;
