import React, { useState } from 'react';
import { colors, Icons } from '@united-talent-agency/components';

import DeleteModal from './Modal';
import { getCommunications, deleteCommunications } from '../../api/communications';
import { DeleteContainer } from './styles';
import { CALL_LIST } from '../../support/cypressTags';

const DeleteCommunication = ({
  selectAll,
  selectExcept = new Set(),
  filterParams,
  onUpdateApplied,
  onExit,
  _deleteCommunications = deleteCommunications,
  _getCommunications = getCommunications,
}) => {
  const [bulkDeleteImpacted, setBulkDeleteImpacted] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { DeleteIcon } = Icons;
  const someSelected = selectAll || selectExcept.size > 0;

  /** Submits call_todo data to the API for bulk delete.
   *
   * @param {*} _deleteCommunications: bulk deletes call_todos. Dependency injected for testing.
   * @returns {void}
   **/
  const submitBulkDelete = () => {
    _deleteCommunications(filterParams, selectAll, Array.from(selectExcept))
      .then((result) => {
        setBulkDeleteImpacted(0);
        onUpdateApplied && onUpdateApplied(result);
      })
      .catch((err) => {
        console.error(err);
        onExit && onExit();
      })
      .finally(() => setShowDeleteModal(false));
  };

  /** Handles bulk delete side effects
   *
   * @param {function} _getCommunications: Gets a list of call_todos based on query params. Dependency injected for testing.
   * @returns {void}
   **/
  const handleBulkDelete = () => {
    const totalImpacted = !selectAll
      ? Promise.resolve(selectExcept.size)
      : _getCommunications(filterParams, { limit: 1 })
          .then(({ meta }) => meta.total)
          .then((totalCount) => totalCount - selectExcept.size);

    return totalImpacted.then((impactedCount) => {
      setBulkDeleteImpacted(impactedCount);
      setShowDeleteModal(true);
    });
  };

  return (
    <>
      <DeleteContainer
        data-cy={CALL_LIST.BULK_DELETE}
        onClick={handleBulkDelete}
        someSelected={someSelected}
      >
        <div style={{ marginRight: '10px' }}>
          <DeleteIcon
            inline
            width={14}
            height={14}
            color={someSelected ? colors.utaBlack : colors.disabledGrey}
          />
        </div>
        Delete
      </DeleteContainer>
      {showDeleteModal && (
        <DeleteModal
          isOpen
          impactedCount={bulkDeleteImpacted}
          onCancel={() => {
            setBulkDeleteImpacted(0);
            onExit && onExit();
            setShowDeleteModal(false);
          }}
          onConfirm={submitBulkDelete}
        />
      )}
    </>
  );
};

export default DeleteCommunication;
