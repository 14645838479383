import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { DatePicker as DefaultDatePicker } from '@united-talent-agency/components';
import { CALL_ROW } from '../../../support/cypressTags';
import dateToAmPm from '../../../utils/dateToAmPm';
import dateToTime from '../../../utils/dateToTime';
import dateToDateString from '../../../utils/dateToDateString';
import dateStringsToDate from '../../../utils/dateStringsTodate';

export default function DatePicker({ occurrenceDate, onClose }) {
  const initialDate = useMemo(() => new Date(occurrenceDate), [occurrenceDate]);

  // Refs
  const dateTime = useRef(initialDate);
  const dateStringRef = useRef(dateToDateString(initialDate));
  const timeStringRef = useRef(dateToTime(initialDate));
  const twelveHourStringRef = useRef(dateToAmPm(initialDate));

  // States
  const [dateString, setDateString] = useState(dateToDateString(initialDate));
  const [timeString, setTimeString] = useState(dateToTime(initialDate));
  const [twelveHourString, setTwelveHourString] = useState(dateToAmPm(initialDate));

  const dateToInputStates = (_date) => {
    const newDateString = dateToDateString(_date);
    const newTimeString = dateToTime(_date);
    const newTwelveHourString = dateToAmPm(_date);

    dateStringRef.current = newDateString;
    timeStringRef.current = newTimeString;
    twelveHourStringRef.current = newTwelveHourString;
    dateTime.current = dateStringsToDate(newDateString, newTimeString, newTwelveHourString);
    setDateString(newDateString);
    setTimeString(newTimeString);
    setTwelveHourString(newTwelveHourString);
  };

  const handleDatetimeChange = useCallback(() => {
    dateTime.current = dateStringsToDate(
      dateStringRef.current,
      timeStringRef.current,
      twelveHourStringRef.current
    );
  }, []);

  useEffect(() => {
    dateToInputStates(initialDate);
  }, [initialDate]);

  const handleOnChangeTime = (_time) => {
    setTimeString(_time);

    if (!_time || _time.length < 4) {
      return;
    }

    timeStringRef.current = _time;
    handleDatetimeChange();
  };

  const handleOnChangeTwelveHourPeriod = (period) => {
    twelveHourStringRef.current = period;
    setTwelveHourString(period);
    handleDatetimeChange();
  };

  const handleOnChange = (_date) => {
    dateStringRef.current = _date;
    setDateString(_date);
    handleDatetimeChange();
  };

  const handleOnBlur = () => {
    onClose && onClose(dateTime.current);
  };

  return (
    <DefaultDatePicker
      open
      focusPicker
      timeIncluded
      inputFieldReadOnly
      dateString={dateString}
      timeString={timeString}
      twelveHourPeriod={twelveHourString}
      dateLabelCypressTag={CALL_ROW.DATE_INNER_LABEL}
      dateButtonCypressTag={CALL_ROW.DATE_DAY_BUTTON}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onChangeTime={handleOnChangeTime}
      onChangeTwelveHourPeriod={handleOnChangeTwelveHourPeriod}
    />
  );
}
